<template>
  <v-dialog
    :value="true"
    max-width="800"
    @click:outside="close"
    scrollable
  >
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ value.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ value.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-card-text>
        <div class="title mt-2">
          {{ $t('general') }}
        </div>
        <v-text-field
          :label="$t('title')"
          v-model="value.title"
        />
        <v-text-field
          :label="$t('description')"
          v-model="value.description"
        />

        <v-divider
          v-if="configFields && configFields.length > 0"
          class="mt-1"
        />

        <div v-if="configFields && configFields.length > 0">
          <div class="title my-3">
            {{ $t('settings') }}
          </div>
          <div>
            <template v-for="configField in configFields">
              <ConfigField
                :key="configField.key"
                :field="configField"
                :instance="value"
              />
            </template>
          </div>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          color="red"
          text
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="value.uuid"
          :disabled="processing"
          :loading="processing"
          color="primary"
          @click="update"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          v-else
          :disabled="processing"
          :loading="processing"
          color="primary"
          @click="create"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfigField from "./ConfigField"

export default {
  name: 'DeliveryMethodInstanceDialog',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    // deliveryMethod: {
    //   type: Object,
    //   default: () => ({})
    // }
  },
  components: {
    ConfigField,
  },
  data: () => ({
    processing: false
  }),
  methods: {
    update: function() {
      this.processing = true
      this.$store.dispatch('updateDeliveryService', this.value).finally(() => {
        this.processing = false
        this.$emit('saved')
      })
    },
    create: function() {
      this.processing = true
      this.$store.dispatch('createDeliveryService', this.value).finally(() => {
        this.processing = false
        this.$emit('saved')
      })
    },
    close() {
      this.$emit('closed')
    }
  },
  computed: {
    deliveryMethod: function () {
      return this.$store.getters.getDeliveryMethod(this.value.namespace)
    },
    configFields() {
      if(typeof this.value !== 'undefined' && this.value !== null) {
        if(Array.isArray(this.value.configFields) && this.value.configFields.length > 0) {
          return this.value.configFields
        }
      }
      if(typeof this.deliveryMethod !== 'undefined' && this.deliveryMethod !== null) {
        if(Array.isArray(this.deliveryMethod.configFields) && this.deliveryMethod.configFields.length > 0) {
          return this.deliveryMethod.configFields
        }
      }
      return []
    }
  }
}
</script>
