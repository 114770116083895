<template>
  <div>
    <v-text-field
      v-if="field.type === 'textfield'"
      :label="field.label"
      v-model="model"
      :required="field.required"
    />
    <v-select
      outlined
      v-if="field.type === 'select'"
      :label="field.label"
      :multiple="field.multiple"
      v-model="model"
      :items="field.options"
      item-text="label"
      item-value="key"
      :required="field.required"
      :small-chips="field.chips"
    />
    <v-combobox
      outlined
      v-if="field.type === 'combobox'"
      :label="field.label"
      :multiple="field.multiple"
      v-model="model"
      :items="field.options"
      item-text="label"
      item-value="key"
      :required="field.required"
      :small-chips="field.chips || field.multiple"
      :deletable-chips="field.chips || field.multiple"
    />
    <v-checkbox
      v-if="field.type === 'checkbox'"
      :label="field.label"
      v-model="model"
      :required="field.required"
    />
    <v-switch
      v-if="field.type === 'switch'"
      :label="field.label"
      v-model="model"
      :required="field.required"
    />
    <v-radio
      v-if="field.type === 'radio'"
      :label="field.label"
      v-model="model"
      :required="field.required"
    />
    <v-select
      v-if="field.type === 'channels'"
      multiple
      :label="field.label"
      v-model="model"
      :items="channels"
      item-text="label"
      item-value="uuid"
      :required="field.required"
    />
  </div>
</template>

<script>
  export default {
    name: 'ConfigField',
    props: {
      field: {
        type: Object,
        default: () => {}
      },
      instance: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
    }),
    computed: {
      model: {
        get: function() {
          return this.instance.data[this.field.key]
        },
        set: function(newValue) {
          this.instance.data[this.field.key] = newValue
        }
      },
      channels()  {
        return this.$store.state.channels
      }
    }
  }
</script>
